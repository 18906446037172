import { AllAffiliationRoles } from "@/constants/userRoles";
export default [
  {
    path: "/affiliation/assignment-report",
    component: () =>
      import(
        /* webpackChunkName: 'AffiliationAssignmentReport' */ "@/views/affiliation/AffiliationAssignmentReport.vue"
      ),
    name: "AffiliationAssignmentReport",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/dashboard",
    component: () =>
      import(
        /* webpackChunkName: 'AffiliationDashboard' */ "@/views/affiliation/AffiliationDashboard.vue"
      ),
    name: "AffiliationDashboard",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/other-reports",
    component: () =>
      import(
        /* webpackChunkName: 'AffiliationOtherReports' */ "@/views/affiliation/AffiliationOtherReports.vue"
      ),
    name: "AffiliationOtherReports",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/my-profile",
    component: () =>
      import(
        /* webpackChunkName: 'AffiliationProfile' */ "@/views/affiliation/AffiliationProfile.vue"
      ),
    name: "AffiliationProfile",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/student-doubts",
    component: () =>
      import(
        /* webpackChunkName: 'AffiliationStudentDoubts' */ "@/views/affiliation/AffiliationStudentDoubts.vue"
      ),
    name: "AffiliationStudentDoubts",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/assignment-questions/:assignmentId",
    component: () =>
      import(
        /* webpackChunkName: 'AssignmentQuestions' */ "@/views/affiliation/AssignmentQuestions.vue"
      ),
    name: "AssignmentQuestions",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/assignment-report/:assignmentId",
    component: () =>
      import(/* webpackChunkName: 'AssignmentReport' */ "@/views/affiliation/AssignmentReport.vue"),
    name: "AssignmentReport",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/report-effort-analysis",
    component: () =>
      import(
        /* webpackChunkName: 'ReportBatchEffortAnalysis' */ "@/views/affiliation/ReportBatchEffortAnalysis.vue"
      ),
    name: "ReportBatchEffortAnalysis",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/report-batch-performance",
    component: () =>
      import(
        /* webpackChunkName: 'ReportBatchPerformance' */ "@/views/affiliation/ReportBatchPerformance.vue"
      ),
    name: "ReportBatchPerformance",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/report-syllabus-coverage",
    component: () =>
      import(
        /* webpackChunkName: 'ReportSyllabusCoverage' */ "@/views/affiliation/ReportSyllabusCoverage.vue"
      ),
    name: "ReportSyllabusCoverage",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/report-test-analysis",
    component: () =>
      import(
        /* webpackChunkName: 'ReportBatchTestAnalysis' */ "@/views/affiliation/ReportBatchTestAnalysis.vue"
      ),
    name: "ReportBatchTestAnalysis",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/create-academic-planner",
    component: () =>
      import(
        /* webpackChunkName: 'CreateAcademicPlanner' */ "@/views/affiliation/CreateAcademicPlanner.vue"
      ),
    name: "CreateAcademicPlanner",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/create-assignment",
    component: () =>
      import(/* webpackChunkName: 'CreateAssignment' */ "@/views/affiliation/CreateAssignment.vue"),
    name: "CreateAssignment",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/create-institute-test",
    component: () =>
      import(
        /* webpackChunkName: 'CreateInstituteTest' */ "@/views/affiliation/CreateInstituteTest.vue"
      ),
    name: "CreateInstituteTest",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/create-live-class",
    component: () =>
      import(/* webpackChunkName: 'CreateLiveClass' */ "@/views/affiliation/CreateLiveClass.vue"),
    name: "CreateLiveClass",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/create-live-practice",
    component: () =>
      import(
        /* webpackChunkName: 'CreateLivePractice' */ "@/views/affiliation/CreateLivePractice.vue"
      ),
    name: "CreateLivePractice",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/edit-assignment/:assignmentId",
    component: () =>
      import(/* webpackChunkName: 'EditAssignment' */ "@/views/affiliation/EditAssignment.vue"),
    name: "EditAssignment",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/edit-institute-test/:instituteTestId",
    component: () =>
      import(
        /* webpackChunkName: 'EditInstituteTest' */ "@/views/affiliation/EditInstituteTest.vue"
      ),
    name: "EditInstituteTest",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/institute-test-offline",
    component: () =>
      import(
        /* webpackChunkName: 'InstituteTestOffline' */ "@/views/affiliation/InstituteTestOffline.vue"
      ),
    name: "InstituteTestOffline",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/institute-test-questions/:instituteTestId",
    component: () =>
      import(
        /* webpackChunkName: 'InstituteTestQuestions' */ "@/views/affiliation/InstituteTestQuestions.vue"
      ),
    name: "InstituteTestQuestions",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/institute-test-report/:instituteTestId",
    component: () =>
      import(
        /* webpackChunkName: 'InstituteTestReport' */ "@/views/affiliation/InstituteTestReport.vue"
      ),
    name: "InstituteTestReport",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/manage-assignments",
    component: () =>
      import(
        /* webpackChunkName: 'ManageAssignments' */ "@/views/affiliation/ManageAssignments.vue"
      ),
    name: "ManageAssignments",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/manage-batches",
    component: () =>
      import(/* webpackChunkName: 'ManageBatches' */ "@/views/affiliation/ManageBatches.vue"),
    name: "ManageBatches",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/manage-faculty",
    component: () =>
      import(/* webpackChunkName: 'ManageFaculty' */ "@/views/affiliation/ManageFaculty.vue"),
    name: "ManageFaculty",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/manage-institute-tests",
    component: () =>
      import(
        /* webpackChunkName: 'ManageInstituteTests' */ "@/views/affiliation/ManageInstituteTests.vue"
      ),
    name: "ManageInstituteTests",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/manage-live-classes",
    component: () =>
      import(
        /* webpackChunkName: 'ManageLiveClasses' */ "@/views/affiliation/ManageLiveClasses.vue"
      ),
    name: "ManageLiveClasses",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/manage-live-practice",
    component: () =>
      import(
        /* webpackChunkName: 'ManageLivePractice' */ "@/views/affiliation/ManageLivePractice.vue"
      ),
    name: "ManageLivePractice",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/manage-users",
    component: () =>
      import(/* webpackChunkName: 'ManageUsers' */ "@/views/affiliation/ManageUsers.vue"),
    name: "ManageUsers",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/coaching-videos",
    component: () =>
      import(/* webpackChunkName: 'MyCoachingVideos' */ "@/views/affiliation/MyCoachingVideos.vue"),
    name: "MyCoachingVideos",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/study-material",
    component: () =>
      import(/* webpackChunkName: 'MyStudyMaterial' */ "@/views/affiliation/MyStudyMaterial.vue"),
    name: "MyStudyMaterial",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/student-roll-numbers",
    component: () =>
      import(
        /* webpackChunkName: 'StudentRollNumbers' */ "@/views/affiliation/StudentRollNumbers.vue"
      ),
    name: "StudentRollNumbers",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/subjective-marking",
    component: () =>
      import(
        /* webpackChunkName: 'SubjectiveMarking' */ "@/views/affiliation/SubjectiveMarking.vue"
      ),
    name: "SubjectiveMarking",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/teaching-content",
    component: () =>
      import(/* webpackChunkName: 'TeachingContent' */ "@/views/affiliation/TeachingContent.vue"),
    name: "TeachingContent",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/test-marks-entry",
    component: () =>
      import(/* webpackChunkName: 'TestMarksEntry' */ "@/views/affiliation/TestMarksEntry.vue"),
    name: "TestMarksEntry",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/students",
    component: () => import(/* webpackChunkName: 'Students' */ "@/views/affiliation/Students.vue"),
    name: "Students",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
  {
    path: "/affiliation/role-wise-users",
    component: () =>
      import(/* webpackChunkName: 'RoleWiseUsers' */ "@/views/affiliation/RoleWiseUsers.vue"),
    name: "RoleWiseUsers",
    meta: {
      requiresAuth: true,
      scrollToTop: true,
      hideFooter: true,
      allowedRoles: AllAffiliationRoles,
    },
  },
];

import { UserRolesEnum } from "@/enums/global";

export const AllUserRoles: ReadonlyArray<string> = Object.freeze([
  UserRolesEnum.ADMIN,
  UserRolesEnum.STUDENT,
  UserRolesEnum.CENTER_MANAGER,
  UserRolesEnum.FACULTY,
  UserRolesEnum.CONTENT_HEAD,
  UserRolesEnum.AFFILIATION_HEAD,
  UserRolesEnum.DEMO_STUDENT,
  UserRolesEnum.TEACHER,
  UserRolesEnum.MODERATOR,
  UserRolesEnum.CONTENT_MANAGER,
  UserRolesEnum.PARENT,
  UserRolesEnum.B2C_INDIVIDUAL_TEACHER,
  UserRolesEnum.SCHOOL_TEACHER,
]);

export const AllStudentRoles: ReadonlyArray<string> = Object.freeze([
  UserRolesEnum.STUDENT,
  UserRolesEnum.DEMO_STUDENT,
]);

export const AllTeacherRoles: ReadonlyArray<string> = Object.freeze([
  UserRolesEnum.FACULTY,
  UserRolesEnum.TEACHER,
  UserRolesEnum.SCHOOL_TEACHER,
]);

export const AllManagerRoles: ReadonlyArray<string> = Object.freeze([
  UserRolesEnum.ADMIN,
  UserRolesEnum.AFFILIATION_HEAD,
  UserRolesEnum.CENTER_MANAGER,
]);

export const AllAffiliationRoles: ReadonlyArray<string> = Object.freeze([
  UserRolesEnum.AFFILIATION_HEAD,
  UserRolesEnum.FACULTY,
  UserRolesEnum.SCHOOL_TEACHER,
]);

export const AllAdminRoles: ReadonlyArray<string> = Object.freeze([UserRolesEnum.ADMIN]);

export const AllContentRoles: ReadonlyArray<string> = Object.freeze([
  UserRolesEnum.CONTENT_HEAD,
  UserRolesEnum.CONTENT_MANAGER,
  UserRolesEnum.ADMIN,
]);

export const ContentApproverRoles: ReadonlyArray<string> = Object.freeze([
  UserRolesEnum.CONTENT_HEAD,
  UserRolesEnum.CONTENT_MANAGER,
  UserRolesEnum.ADMIN,
]);
